'use client'
import { useEffect, useState } from 'react'
import './page.module.styles.css'
// import Image from 'next/image'
import { Spin } from 'antd'
import CardProduct from '../card/page'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import 'swiper/css/scrollbar'
import * as Icon from 'react-feather'
import { useRouter } from 'next/navigation'
import useProductsFlashSale from '../../lib/flash-sale/useProductsFlashSale'
import useBanners from 'src/app/lib/useBanners'
const Campaign = () => {
  const router = useRouter()
  const [option, setOption] = useState({
    page: 1,
    limit: 30,
  })
  const {
    // bannerFlashSale,
    mutateBanners,
    // isLoading: loadingBanner,
  } = useBanners()

  // const imageUrl =
  //   bannerFlashSale &&
  //   bannerFlashSale[0]?.image?.data.map((item) => item.attributes)

  // const description = bannerFlashSale && bannerFlashSale[0]?.description

  // const { Countdown } = Statistic
  // const deadline = new Date(Date.now() + 24 * 60 * 60 * 1000)
  // const onFinish = () => {
  //   console.log('finished!')
  // }

  const { flashSale, mutateFlashSale, loading } = useProductsFlashSale(option)

  const { data } = flashSale || {}

  useEffect(() => {
    mutateFlashSale
    mutateBanners
    setOption((prevOption) => ({
      ...prevOption,
      page: 1,
    }))
  }, [mutateFlashSale, mutateBanners])

  return (
    <Spin spinning={loading}>
      <div className="w-full flex mt-4 sm:mt-10">
        <div className="container mx-auto sm:bg-white">
          <div className="cantainer-slide">
            {/* <div className="hidden sm:flex banner-first-child">
              {imageUrl && (
                <Image
                  src={imageUrl[0]?.url}
                  alt={description ? description : `flash-sale-2023`}
                  className="w-full"
                  width={100}
                  height={100}
                />
              )}
            </div> */}
            <div className="flex flex-col wrap-slide">
              <div className="flex w-full justify-between items-center">
                <div className="flex flex-row items-center">
                  <h3 className="text-heder-component">FLASH SALE ดีลเดือด</h3>
                  {/* <Countdown
                  value={deadline}
                  onFinish={() => {
                    onFinish()
                  }}
                  format="HH:mm:ss"
                  className="ml-4"
                  valueStyle={{
                    color: '#ffffff',
                    fontSize: '1rem',
                    lineHeight: '1rem',
                    padding: '.3125rem',
                    borderRadius: '.525rem',
                    fontWeight: 500,
                    background:
                      'transparent linear-gradient(180deg, #EA0000 0%, #750000 100%)',
                  }}
                /> */}
                </div>
                <div
                  href="#"
                  className="flex flex-row items-center cursor-pointer"
                  onClick={() => {
                    router.push(`/product-flash?search=${'flash'}`, undefined, {
                      shallow: true,
                    })
                  }}
                >
                  <span className="text-see-all">ดูทั้งหมด</span>
                  <div className="icon-see-all">
                    <Icon.ArrowRight className="w-3 h-3" />
                  </div>
                </div>
              </div>
              <div className="flex">
                <span className="underline-text"></span>
              </div>
              <div className="swiper-container">
                <Swiper
                  className="m-0"
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  loop={true}
                  navigation
                  slidesPerView={2.25}
                  spaceBetween={10}
                  breakpoints={{
                    640: {
                      slidesPerView: 2.5,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1024: {
                      slidesPerView: 5,
                      spaceBetween: 20,
                    },
                    1280: {
                      slidesPerView: 5,
                      spaceBetween: 20,
                    },
                    1536: {
                      slidesPerView: 6,
                      spaceBetween: 20,
                    },
                  }}
                >
                  {/* slice(0, 30) */}
                  {data?.map((item) => {
                    return (
                      <SwiperSlide
                        className="min-w-40 w-full max-w-[165px] lg:max-w-[233px]"
                        key={`campaign_${item?.num_iid}`}
                      >
                        <CardProduct
                          imageUrl={item?.pic_url}
                          alt={item?.title}
                          tagName={item?.source}
                          title={item?.title}
                          priceTHB={item && item.priceBaht ? item.priceBaht : '00.00'}
                          priceCNY={item && item.price ? item.price : '00.00'}
                          // promotionPriceTHB={item?.promotion_price_thb}
                          // promotionPriceCNY={item?.promotion_price}
                          className="custom-shadow"
                          onClick={() => {
                            router.push(`/product/campaign/${item.num_iid}/${item.source}`)
                          }}
                        />
                      </SwiperSlide>
                    )
                  })}
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Spin>
  )
}
export default Campaign
